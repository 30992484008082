import { PropsWithChildren, useState, type FunctionComponent } from 'react';
import {
  CognitoAuthContext,
  CognitoUser,
  CognitoActionContext,
  AuthenticationStateParams,
} from './';

export interface CognitoMockProviderProps extends PropsWithChildren {
  loading?: boolean;
  authenticated?: boolean;
  token?: string | null;
  user?: Partial<CognitoUser> | null;
  signOutSpy?: () => void;
  refetchMetadataSpy?: () => void;
  redirectToLoginSpy?: (state?: AuthenticationStateParams) => void;
  redirectAfterLoginSpy?: () => void;
}

const notImplementedStub = () => {
  throw new Error('Not implemented in CognitoMockProvider test environment.');
};

const DEFAULT_TEST_USER = {
  email: 'test@example.com',
  emailVerified: true,
  isSSOAuthUser: false,
  staffId: '8a94908b-0953-4886-9e9d-5c6d418bd187',
};

export const CognitoMockProvider: FunctionComponent<
  CognitoMockProviderProps
> = ({
  loading = false,
  authenticated = !loading,
  token = 'test-cognito-token',
  user = {},
  signOutSpy,
  refetchMetadataSpy,
  redirectToLoginSpy,
  redirectAfterLoginSpy,
  children,
}) => {
  const [error, setError] = useState<Error | null>(null);
  const [authToken, setAuthToken] = useState(authenticated ? token : null);
  const [authUser, setAuthUser] = useState(
    authenticated
      ? {
          ...DEFAULT_TEST_USER,
          ...user,
        }
      : null
  );

  const clearAuthState = () => {
    setAuthToken(null);
    setAuthUser(null);
  };

  return (
    <CognitoAuthContext.Provider
      value={{
        loading: loading && !authenticated,
        refreshing: loading,
        error: error,
        token: authToken,
        authUser,
        setError,
        clearAuthState,
        // Functions for manipulating the auth state are not implemented in this mock, as they
        // the scope of this mock is to provide a static snapshot auth state for testing.
        setToken: notImplementedStub,
        setRefreshing: notImplementedStub,
      }}>
      <CognitoActionContext.Provider
        value={{
          error: null,
          handleSignOut: () => Promise.resolve(signOutSpy?.() ?? undefined),
          refetchUserMetadata: () =>
            Promise.resolve(refetchMetadataSpy?.() ?? undefined),
          redirectToLogin: () =>
            Promise.resolve(redirectToLoginSpy?.() ?? undefined),
          redirectAfterLogin: () => redirectAfterLoginSpy?.(),
        }}>
        {children}
      </CognitoActionContext.Provider>
    </CognitoAuthContext.Provider>
  );
};
